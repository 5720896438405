import React from "react";

class ContactPage extends React.Component {

  render() {
    return (
      <>
        Contact Page
      </>
    );
  }
}

export default ContactPage;
